.loading {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-grow: 1;
  min-height: 100vh;
}

.loading:before {
  content: '';
  width: 150px;
  height: 150px;
  border: 10px solid #bcbdbe;
  border-bottom-color: transparent;
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  animation: rotation 1s linear infinite, fadein 0.3s linear 0.3s both;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg)
  }

  100% {
    transform: rotate(360deg)
  }
}