.error-404 {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: whitesmoke;
  font-size: 2rem;
}

.error-404 h1 {
  font-size: 3rem;
}

.error-404 a {
  text-decoration: none;
  width: 200px;
  height: 50px;
  line-height: 50px;
  text-align: center;
  padding: .5rem;
  border: none;
  border-radius: 10px;
  cursor: pointer;
  background-color: #0a3a8a;
  color: #d2dae2;
  font-weight: bold;
}