.picture-of-the-day {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex-grow: 1;
    min-width: 100%;
    min-height: 100vh;
    margin-left: 200px;
    padding: 2rem 1rem;
    gap: 1rem;
    background-image: url('../assets/jpg/stars-background.jpg');
    background-size: contain;
    background-position: center;
}

.picture-of-the-day h2 {
    font-family: 'Press Start 2P', cursive;
}

.picture-of-the-day form {
    display: flex;
    gap: 2rem;
    align-items: flex-end;
}


.picture-of-the-day form button {
    width: 100px;
    height: 30px;
    border: none;
    border-radius: 5px;
    background-color: #0a3a8a;
    color: #d2dae2;
    font-weight: bold;
    cursor: pointer;
}


.picture-of-the-day form label {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 300px;
    gap: .5rem;
    color: #d2dae2;
    font-weight: bold;
}


.picture-of-the-day form label input[type='date'] {
    width: 100%;
    border: none;
    border-radius: 5px;
    background-color: rgb(147, 128, 128);
    padding: .5rem;
}


.picture-of-the-day .card-image-of-day {
    flex-grow: 1;
    max-width: 800px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: flex-start;
    padding: .5rem;
    background-color: rgba(37, 38, 39, 0.515);
    border-radius: 15px;
    color: #d2dae2;
    padding: 2rem;
    gap: 1rem;
}


.picture-of-the-day h2,
.picture-of-the-day .card-image-of-day span {
    color: #d2dae2;
    padding: 0;
    margin: 0;
    font-size: 1.5rem;
    font-weight: bold;
}

.picture-of-the-day .card-image-of-day img {
    width: 100%;
    border-radius: 15px;
}

.picture-of-the-day .card-image-of-day iframe {
    flex-grow: 1;
}


.picture-of-the-day .card-image-of-day .data-image {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding-top: 1rem;
    font-size: .9rem;
    gap: .5rem;
}


@media (max-width: 768px) {
    .picture-of-the-day {
        margin-left: 0;
        gap: 2rem;
        padding: 2rem 0;
    }

    .picture-of-the-day h2 {
        font-size: 1.2rem;
        width: 350px;
        line-height: 30px
    }

    .picture-of-the-day .card-image-of-day {
        width: 100vw;
        gap: 1rem;
        padding: 0;
    }

    .picture-of-the-day .card-image-of-day img {
        width: 100%;
    }

    .picture-of-the-day .card-image-of-day .data-image {
        padding: .5rem;
    }

    .picture-of-the-day .card-image-of-day span {
        font-size: 1.5rem;
    }

    .picture-of-the-day form {
        width: 90%;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .picture-of-the-day form label {
        width: 300px;
        flex-direction: row;
        flex-wrap: wrap;
    }

    .picture-of-the-day form label input[type='date'] {
        width: 300px;
        height: 40px;
        font-size: 1.2rem;
        padding-left: 1rem;
    }

    .picture-of-the-day form button {
        width: 350px;
        height: 50px;
        font-size: 1.2rem;
        border-radius: none;
    }
}