header {
    color: whitesmoke;
    background-color: #1e272e;
    width: 200px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    position: fixed;
    font-family: 'Trispace', sans-serif;
    top: 0;
    bottom: 0;
    left: 0;
    padding-bottom: 1rem;
}

header .logo-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}


header .logo {
    background-image: url('../assets/png/nasa-logo.png');
    width: 170px;
    height: 170px;
    background-size: cover;
}

header .link-to-home {
    font-size: 2rem;
    color: #d2dae2;
    font-weight: bolder;
    margin: 0;
}


header ul {
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 0;
}


header ul li {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 50px;
    list-style: none;
    border-top: 1px solid #808e9b;
}


header ul li .link-menu-item {
    text-decoration: none;
    color: #d2dae2;
}

header ul li:nth-child(5) {
    border-bottom: 1px solid #808e9b;
}


header ul li .active {
    color: #0984e3;
    font-weight: bolder;
}


header .links-logos-container {
    display: flex;
    width: 100%;
    justify-content: center;
    gap: 1rem;
    margin-bottom: 1rem;
}



header .links-logos-container .rss-logo {
    color: #d2dae2;
    background-size: cover;
    width: 35px;
    height: 35px;
}

header .myName {
    text-decoration: none;
    color: #d2dae2;
    padding: 2rem 0;
}


header .close-icon-container {
    display: none;
}



@media (max-width: 768px) {
    header {
        width: 100vw;
        display: flex;
        position: relative;
    }

    header .logo-container {
        width: 100%;
        padding: 1rem 0 0;
        flex-direction: row;
        justify-content: center;
        gap: 1rem;
    }

    header .logo {
        width: 150px;
        height: 122px;
    }

    header .link-to-home {
        font-size: 2.5rem;
        text-align: right;
        width: min-content;
    }

    header .close-icon-container {
        display: inline-block;
        text-align: center;
        padding: 1rem 0 0;
    }
}