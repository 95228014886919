.mars-weather-page {
    margin-left: 200px;
    max-width: 100vw;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    color: #d2dae2;
    background-image: url('../assets/jpg/rover-curiosity.jpg');
    background-size: cover;
    padding: 2rem 0 0 0;
}


.mars-weather-page h2 {
    margin: 0 auto;
    font-family: 'Press Start 2P', cursive;
}


.mars-weather-page .weather-cards-container {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 2rem;
    background-color: rgba(0, 0, 0, 0.214);
    border-radius: 30px;
}


.mars-weather-page .weather-cards-container .weather-card {
    background-color: rgba(114, 111, 111, 0.279);
    width: 200px;
    height: 300px;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    align-items: center;
    border-radius: 20px;
    margin-bottom: 1rem;
    gap: .2rem;
    padding: 2rem 0;
}



.mars-weather-page .weather-cards-container .weather-card:first-child {
    background-color: transparent;
    width: 100%;
    height: 250px;
    flex-direction: row;
    justify-content: center;
    margin-bottom: 2rem;
}


.mars-weather-page .weather-cards-container .weather-card:first-child * {
    width: 45%;
    font-size: 2rem;
    font-weight: bolder;
    margin: 0;
    text-align: center;
}

.mars-weather-page .weather-cards-container .weather-card:first-child h3 {
    font-size: 3rem;
}

.mars-weather-page .weather-cards-container .weather-card:first-child span {
    font-size: 2.5rem;
}

@media (max-width: 768px) {
    .mars-weather-page {
        margin-left: 0;
        padding: 2rem 0;
        height: min-content;
        gap: 2rem;
    }

    .mars-weather-page .weather-cards-container {
        width: 100%;
        padding: 0;
    }

    .mars-weather-page .weather-cards-container .weather-card {
        width: 100%;
        flex-direction: row;
    }

    .mars-weather-page .weather-cards-container .weather-card h3 {
        font-size: 1.5rem;
        width: 100%;
        text-align: center;
    }

    .mars-weather-page .weather-cards-container .weather-card:first-child h3 {
        font-size: 2rem;
        width: 100%;
        text-align: center;
    }

    .mars-weather-page .weather-cards-container .weather-card span {
        font-size: 1.5rem;
        width: 100%;
        text-align: center;
    }

    .mars-weather-page .weather-cards-container .weather-card:first-child span {
        font-size: 2rem;
        width: 100%;
        text-align: center;
    }

    .mars-weather-page .weather-cards-container .weather-card p {
        width: 49%;
        text-align: center;
    }

    .mars-weather-page .weather-cards-container .weather-card:first-child * {
        font-size: 1rem;
    }
}