.epic-page .epic-images-container {
    flex-grow: 1;
    display: flex;
    justify-content: space-between;
    gap: 2rem;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    padding-bottom: 4rem;
}

.epic-page .epic-images-container .epic-image-card {
    width: 500px;
    min-height: 650px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 1rem;
    background-color: rgba(114, 111, 111, 0.279);
    border-radius: 15px;
    color: #d2dae2;
    padding: 1rem;
    font-size: .7rem;
}

.epic-page .epic-images-container .epic-image-card p {

    font-weight: bold;
}

.epic-page .epic-images-container img {
    margin: 0 auto;
    width: 100%;
    border-radius: 15px;
}

.epic-page .epic-images-container .epic-image-card .epic-image__data {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
}

.epic-page .epic-images-container .epic-image-card .epic-image__data .epic-image__data-items div {
    background-color: rgb(0, 0, 0);
    padding: 1rem .5rem;
    border-radius: 5px;
    height: 80px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: #d63031;
}


.epic-page .epic-images-container .epic-image-card .epic-image__data span {
    color: #55efc4;
    font-weight: normal;
}


.epic-page .epic-images-container .epic-image-card .date {
    text-align: center;
    font-size: 1rem;
    font-weight: bold;
}

@media (max-width: 768px) {
    .epic-page .epic-images-container .epic-image-card {
        width: 100vw;
        padding: 1rem 0;
    }

    .epic-page .epic-images-container img {
        width: 100%;
    }

    .epic-page .epic-images-container .epic-image-card .epic-image__data {
        flex-wrap: wrap;
        padding: .5rem;
    }

    .epic-page .epic-images-container .epic-image-card .epic-image__data .epic-image__data-items div {
        width: 150px;
    }
}