.images-videos-page {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    color: #d2dae2;
    margin-left: 200px;
    box-sizing: border-box;
    gap: 2rem;
    background-image: url('../assets/jpg/stars-background.jpg');
    background-size: contain;
    background-position: center;
    padding: 2rem 0;
}


.images-videos-page h2 {
    font-family: 'Press Start 2P', cursive;
}


.images-videos-page .search-container {
    width: 100%;
    display: flex;
    gap: 2rem;
    align-items: flex-end;
    justify-content: center;
    flex-wrap: wrap;
}


.images-videos-page form input[type='text'] {
    padding: .5rem;
    background-color: rgba(114, 111, 111, 0.279);
    color: #d2dae2;
    border: none;
    border-radius: 5px;
}


.images-videos-page .buttons-media-type-container {
    display: flex;
    gap: .5rem;
    justify-content: space-between;
}


.images-videos-page .buttons-media-type-container button {
    width: 100px;
    height: 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    background-color: #0a3a8a;
    color: #d2dae2;
    font-weight: bold;
}

.images-videos-page .buttons-media-type-container button.active {
    background-color: rgb(197, 70, 58);
}


.no-results {
    height: 300px;
    width: 100%;
    text-align: center;
    font-size: 2rem;
}

.images-videos-page .preview-container {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: flex-end;
    gap: 1rem;
}


.images-videos-page .buttons-container {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 2rem;
    padding: 3rem;
}

.images-videos-page .buttons-container button {
    padding: .5rem;
    width: 100px;
    border: none;
    border-radius: 5px;
    font-weight: bold;
    cursor: pointer;
    background-color: #3e5e94;
    color: #d2dae2;
}

.images-videos-page .buttons-container span {
    color: #d2dae2;
    font-weight: bold;
    font-size: 1.3rem;
}

.images-videos-page .scroll-up-button {
    font-size: 2.5rem;
    position: fixed;
    bottom: 3rem;
    right: 3rem;
    cursor: pointer;
}

@media (max-width: 768px) {
    .images-videos-page {
        align-items: center;
        color: whitesmoke;
        margin-left: 0;
        box-sizing: border-box;
    }

    .images-videos-page h2 {
        font-size: 1.5rem;
        padding-top: 1rem;
    }

    .images-videos-page form input[type='text'] {
        width: 300px;
        padding: .5rem;
        font-size: 1.5rem;
    }

    .images-videos-page .buttons-media-type-container {
        width: 100%;
        flex-direction: column;
        gap: .5rem;
        justify-content: space-between;
    }


    .images-videos-page .buttons-media-type-container button {
        width: 100%;
        height: 40px;
        border: none;
        border-radius: 0;
        font-size: 1.3rem;
    }

    .images-videos-page .scroll-up-button {
        display: none;
    }

}