@import "normalize.css";

* {
  scroll-behavior: smooth;
}

.App {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  background-color: rgb(17, 16, 16);
  font-family: sans-serif;
}