.filter-background {
    width: 100vw;
    flex-grow: 1;
    background-image: url('../assets/jpg/background.jpg');
    background-size: cover;
    font-family: 'Press Start 2P', cursive;
}


.home-page {
    padding-left: 250px;
    color: #d2dae2;
    box-sizing: border-box;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    background-color: rgba(0, 0, 0, 0.634);
}


.home-page h1 {
    font-size: 2.5rem;
    line-height: 100px;
    letter-spacing: 2px;
    margin: 0;
}


@media (max-width: 768px) {
    .home-page {
        padding-left: 2rem;
    }

    .home-page h1 {
        display: none;
    }
}