.mars-rover-photos-page {
    display: flex;
    flex-grow: 1;
    min-height: 100vh;
    margin-left: 200px;
    padding: 2rem;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    gap: .5rem;
    box-sizing: border-box;
    background-image: url('../assets/jpg/stars-background.jpg');
    background-size: contain;
    background-position: center;
    color: #d2dae2;
}


.mars-rover-photos-page h2 {
    width: 100%;
    font-family: 'Press Start 2P', cursive;
    text-align: center;
}


.mars-rover-photos-page .photos-container {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    gap: 2rem;

}


.mars-rover-photos-page .mars-rover-photos-card {
    width: 400px;
    min-height: 400px;
    background-color: rgba(114, 111, 111, 0.279);
    display: flex;
    align-items: center;
    padding: 1rem;
    border-radius: 10px;
}

.mars-rover-photos-page .mars-rover-photos-card img {
    width: 100%;
    cursor: pointer;
}


.mars-rover-photos-page .buttons-container {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 2rem;
    padding: 3rem;
}


.mars-rover-photos-page .buttons-container button {
    padding: .5rem;
    width: 100px;
    border: none;
    border-radius: 5px;
    font-weight: bold;
    cursor: pointer;
    background-color: #3e5e94;
    color: #d2dae2;
}


.mars-rover-photos-page .buttons-container span {
    color: #d2dae2;
    font-size: 1.3rem;
    font-weight: bold;
}

.mars-rover-photos-page .scroll-up-button {
    font-size: 2.5rem;
    position: fixed;
    bottom: 3rem;
    right: 3rem;
    cursor: pointer;
}


@media (max-width: 768px) {
    .mars-rover-photos-page {
        margin-left: 0;
        padding: 2rem 0;
    }

    .mars-rover-photos-page .photos-container {
        flex-direction: column;
    }

    .mars-rover-photos-page .mars-rover-photos-card {
        width: 100%;
        padding: 0;
    }

    .mars-rover-photos-page .mars-rover-photos-card img {
        width: 100%;
    }

    .mars-rover-photos-page .buttons-container {
        flex-direction: column;
    }

    .mars-rover-photos-page .buttons-container button {
        width: 100%;
        padding: 1rem;
        font-size: 1.2rem;
    }

    .mars-rover-photos-page .buttons-container span {
        font-size: 1.3rem;
    }

    .mars-rover-photos-page .scroll-up-button {
        display: none;
    }

}