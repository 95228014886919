.epic-page {
    min-height: 100vh;
    display: flex;
    margin-left: 200px;
    align-items: center;
    flex-direction: column;
    justify-content: space-around;
    flex-wrap: wrap;
    box-sizing: border-box;
    padding: 2rem 0 2rem 0;
    gap: 2rem;
    background-image: url('../assets/jpg/stars-background.jpg');
    background-size: contain;
    background-position: center;
}

.epic-page h2 {
    margin: 0 auto;
    font-family: 'Press Start 2P', cursive;
    color: whitesmoke;
    padding: 0;

}


.epic-page .menu-filter {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    gap: 2rem;
}


.epic-page .menu-filter form {
    box-sizing: border-box;
    display: flex;
    align-items: flex-end;
    justify-content: center;
    gap: 1rem;
    width: 100%;
}


.epic-page .menu-filter form label {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: .5rem;
}


.epic-page .menu-filter form label input[type='date'],
.epic-page .menu-filter form select {
    width: 300px;
    height: 30px;
    font-size: .7rem;
    border: none;
    border-radius: 5px;
    font-size: 1rem;
}

.epic-page .menu-filter form button {
    background-color: #0a3a8a;
    width: 200px;
    height: 30px;
    border: none;
    border-radius: 5px;
    color: #d2dae2;
    font-weight: bold;
    padding: .5rem;
}

.epic-page .scroll-up-button {
    font-size: 2.5rem;
    position: fixed;
    bottom: 3rem;
    right: 3rem;
    cursor: pointer;
}


@media (max-width: 968px) {
    .epic-page {
        margin-left: 0;
    }

    .epic-page .menu-filter form {
        width: 100%;
        flex-direction: column;
        align-items: center;
        gap: 1rem;
    }

    .epic-page .menu-filter form label input[type='date'],
    .epic-page .menu-filter form select {
        width: 350px;
        height: 40px;
        padding: 0 .5rem;
    }

    .epic-page .menu-filter form button {
        width: 350px;
        height: 40px;
        font-size: 1.3rem;
    }

    .epic-page .scroll-up-button {
        display: none;
    }

}