.images-videos-page .preview-container .preview-card {
    width: 400px;
    min-height: 450px;
    background-color: rgba(129, 125, 125, 0.253);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    gap: 2rem;
    border-radius: 5px;
    padding: .7rem;
}


.images-videos-page .preview-container .preview-card .icon-mediatype {
    align-self: flex-start;
    font-size: 2rem;
    padding: .5rem;
}


.images-videos-page .preview-container .preview-card p {
    font-size: 1.3rem;
    font-weight: bold;
}


.preview-card img {
    cursor: pointer;
    width: 100%;
}

.preview-video-conatiner {
    z-index: 1;
    width: 100%;
    min-height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 2rem;
    background-color: rgba(15, 14, 14, 0.851);
    position: fixed;
    left: 0;
    bottom: 0;
}

.preview-video-conatiner label {
    font-size: 2rem;
    width: 100%;
    text-align: center;
}


.preview-video-conatiner video {
    width: 60vw;
    background-color: rgba(15, 14, 14, 0.851);
    padding: 2rem;
}


.preview-video-conatiner img {
    width: 1080px;
    height: 960;
    height: 60vh;
    background-color: rgba(255, 255, 255, 0.495);
    padding: 2rem;
}


.preview-video-conatiner p {
    width: 700px;
}


.preview-video-conatiner button {
    border: none;
    padding: .5rem;
    width: 100px;
    border-radius: 5px;
    background-color: rgba(255, 0, 0, 0.425);
    color: whitesmoke;
    font-weight: bolder;
    margin: 0 20rem;
    cursor: pointer;
}

@media (max-width: 768px) {
    .images-videos-page .preview-container .preview-card {
        width: 100%;
        gap: 1rem;
        padding: .7rem 0;
    }

    .preview-card img {
        cursor: pointer;
        width: 100%;
        height: 300px;
    }

    .preview-card p {
        font-size: 1.5rem;
    }

}